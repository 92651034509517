// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MSTeamsSettings-module__rXBc3bygWWzIhPJAvHJW {\n  display: block;\n}\n\n.MSTeamsSettings-module__CxuwipcTk3E42I_qEIXb {\n  display: flex;\n  justify-content: space-between;\n}\n", "",{"version":3,"sources":["webpack://./pages/settings/tabs/ChatOps/tabs/MSTeamsSettings/MSTeamsSettings.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC","sourcesContent":[".root {\n  display: block;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "MSTeamsSettings-module__rXBc3bygWWzIhPJAvHJW",
	"header": "MSTeamsSettings-module__CxuwipcTk3E42I_qEIXb"
};
export default ___CSS_LOADER_EXPORT___;
