// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MSTeamsInstructions-module__y1zOj7AuEWRt23PWM1N5 {\n  width: 752px;\n  text-align: center;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n}\n\n.MSTeamsInstructions-module__iBZjprplXDs87kIc7iDn {\n  margin-top: 8px;\n  width: 752px;\n}\n\n.MSTeamsInstructions-module__iBZjprplXDs87kIc7iDn input {\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n  color: var(--primary-text-link);\n}\n\n.MSTeamsInstructions-module__VTBv9dsNJGb_XKoZ1VTK {\n  margin-left: 48px;\n  margin-right: 48px;\n}\n\n.MSTeamsInstructions-module__WZyMEfzSlciDttBXyXjw {\n  width: 752px;\n  direction: rtl;\n}\n", "",{"version":3,"sources":["webpack://./containers/MSTeams/MSTeamsInstructions.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,+BAA+B;AACjC;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB","sourcesContent":[".info-block {\n  width: 752px;\n  text-align: center;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n}\n\n.field-command {\n  margin-top: 8px;\n  width: 752px;\n}\n\n.field-command input {\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n  color: var(--primary-text-link);\n}\n\n.infoblock-text {\n  margin-left: 48px;\n  margin-right: 48px;\n}\n\n.done-button {\n  width: 752px;\n  direction: rtl;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info-block": "MSTeamsInstructions-module__y1zOj7AuEWRt23PWM1N5",
	"field-command": "MSTeamsInstructions-module__iBZjprplXDs87kIc7iDn",
	"infoblock-text": "MSTeamsInstructions-module__VTBv9dsNJGb_XKoZ1VTK",
	"done-button": "MSTeamsInstructions-module__WZyMEfzSlciDttBXyXjw"
};
export default ___CSS_LOADER_EXPORT___;
