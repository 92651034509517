// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewScheduleSelector-module__ZHXjOgGPLmd3fq0XYKGv {\n  display: block;\n}\n\n.NewScheduleSelector-module__e663WQdqFGOPRTr6oSGh {\n  width: 100%;\n}\n\n.NewScheduleSelector-module__qd39JdZ1SitNL6ZegY0D {\n  padding-bottom: 24px;\n}\n", "",{"version":3,"sources":["webpack://./components/NewScheduleSelector/NewScheduleSelector.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".root {\n  display: block;\n}\n\n.block {\n  width: 100%;\n}\n\n.content {\n  padding-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "NewScheduleSelector-module__ZHXjOgGPLmd3fq0XYKGv",
	"block": "NewScheduleSelector-module__e663WQdqFGOPRTr6oSGh",
	"content": "NewScheduleSelector-module__qd39JdZ1SitNL6ZegY0D"
};
export default ___CSS_LOADER_EXPORT___;
