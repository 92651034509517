// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Connectors-module__WgFQg2CFk4fcrdUZqIJ5 {\n  margin-bottom: 15px;\n}\n\n.Connectors-module__pKgeMYszWRo97K5AXueR {\n  font-size: 16px;\n}\n\n.Connectors-module___uSlR2SAtamz_aI_yuTe {\n  display: block;\n}\n\n.Connectors-module__tjqmqPBJprt_yu9OxMQh {\n  margin-top: 24px;\n}\n\n.Connectors-module__MVq9_n5fpM5Q4vq9iqal {\n  margin-top: 8px;\n}\n\n.Connectors-module__aPssP5L6uXW2LQUTaL7S {\n  display: block;\n  border-radius: 2px;\n  border: var(--border);\n  padding: 4px;\n  background-color: var(--secondary-background);\n  overflow-wrap: break-word;\n}\n\n.Connectors-module__uO2TkxfyCmqt0w_ubwYT {\n  color: var(--warning-text-color);\n}\n\n.Connectors-module__XXKjxJy2dhFnOvRGf4yL {\n  color: var(--error-text-color);\n}\n", "",{"version":3,"sources":["webpack://./containers/UserSettings/parts/connectors/Connectors.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,qBAAqB;EACrB,YAAY;EACZ,6CAA6C;EAC7C,yBAAyB;AAC3B;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".user-item {\n  margin-bottom: 15px;\n}\n\n.user-value {\n  font-size: 16px;\n}\n\n.iCal-settings {\n  display: block;\n}\n\n.iCal-button {\n  margin-top: 24px;\n}\n\n.iCal-link-container {\n  margin-top: 8px;\n}\n\n.iCal-link {\n  display: block;\n  border-radius: 2px;\n  border: var(--border);\n  padding: 4px;\n  background-color: var(--secondary-background);\n  overflow-wrap: break-word;\n}\n\n.warning-icon {\n  color: var(--warning-text-color);\n}\n\n.error-message {\n  color: var(--error-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user-item": "Connectors-module__WgFQg2CFk4fcrdUZqIJ5",
	"user-value": "Connectors-module__pKgeMYszWRo97K5AXueR",
	"iCal-settings": "Connectors-module___uSlR2SAtamz_aI_yuTe",
	"iCal-button": "Connectors-module__tjqmqPBJprt_yu9OxMQh",
	"iCal-link-container": "Connectors-module__MVq9_n5fpM5Q4vq9iqal",
	"iCal-link": "Connectors-module__aPssP5L6uXW2LQUTaL7S",
	"warning-icon": "Connectors-module__uO2TkxfyCmqt0w_ubwYT",
	"error-message": "Connectors-module__XXKjxJy2dhFnOvRGf4yL"
};
export default ___CSS_LOADER_EXPORT___;
