// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MSTeamsIntegrationButton-module__Zr4xoqxOQtvfPbZn0HS9 {\n  color: var(--primary-text-link);\n}\n\n.MSTeamsIntegrationButton-module__ZueOZQmtTbaW3rCe1EHq {\n  display: block;\n  text-align: left;\n  margin-bottom: 12px;\n}\n\n.MSTeamsIntegrationButton-module__wlev2SCzgmyLkNWoFyZJ {\n  text-decoration: underline;\n}\n\n.MSTeamsIntegrationButton-module__ZG6_hb_dWa32r0YbDBKH {\n  color: var(--primary-text-link);\n}\n\n.MSTeamsIntegrationButton-module__e2vn4X8sO3PTPCAxACef {\n  margin-top: 24px;\n}\n\n.MSTeamsIntegrationButton-module__JC9XlcEVcNDTPmV2pwQD {\n  min-width: 800px;\n}\n", "",{"version":3,"sources":["webpack://./containers/MSTeamsIntegrationButton/MSTeamsIntegrationButton.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".msteams-bot {\n  color: var(--primary-text-link);\n}\n\n.msteams-instruction-container {\n  display: block;\n  text-align: left;\n  margin-bottom: 12px;\n}\n\n.verification-code {\n  text-decoration: underline;\n}\n\n.copy-icon {\n  color: var(--primary-text-link);\n}\n\n.msteams-instruction-cancel {\n  margin-top: 24px;\n}\n\n.msTeams-modal {\n  min-width: 800px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"msteams-bot": "MSTeamsIntegrationButton-module__Zr4xoqxOQtvfPbZn0HS9",
	"msteams-instruction-container": "MSTeamsIntegrationButton-module__ZueOZQmtTbaW3rCe1EHq",
	"verification-code": "MSTeamsIntegrationButton-module__wlev2SCzgmyLkNWoFyZJ",
	"copy-icon": "MSTeamsIntegrationButton-module__ZG6_hb_dWa32r0YbDBKH",
	"msteams-instruction-cancel": "MSTeamsIntegrationButton-module__e2vn4X8sO3PTPCAxACef",
	"msTeams-modal": "MSTeamsIntegrationButton-module__JC9XlcEVcNDTPmV2pwQD"
};
export default ___CSS_LOADER_EXPORT___;
