// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TemplatePreview-module__NbOm9swpLK7NOS93SwTs {\n  width: 100%;\n}\n\n.TemplatePreview-module__vJFelPuZL9QlRtTSA9EW {\n  word-wrap: break-word;\n}\n\n.TemplatePreview-module__vJFelPuZL9QlRtTSA9EW ul {\n  margin-left: 24px;\n}\n\n.TemplatePreview-module__vJFelPuZL9QlRtTSA9EW p {\n  margin-bottom: 0;\n}\n\n.TemplatePreview-module__vJFelPuZL9QlRtTSA9EW code {\n  white-space: break-spaces;\n}\n\n.TemplatePreview-module__kBQqa84BqJ0KaQc1001Q img {\n  max-width: 100%;\n  max-height: 100%;\n}\n\n.TemplatePreview-module___0sOoCuTC6fL9KvBdnvv {\n  white-space: pre-line;\n}\n\n.TemplatePreview-module__bbcpZTKj7Mui49oErh_u {\n  margin-bottom: 10px;\n}\n", "",{"version":3,"sources":["webpack://./containers/TemplatePreview/TemplatePreview.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".img {\n  width: 100%;\n}\n\n.message {\n  word-wrap: break-word;\n}\n\n.message ul {\n  margin-left: 24px;\n}\n\n.message p {\n  margin-bottom: 0;\n}\n\n.message code {\n  white-space: break-spaces;\n}\n\n.image-result img {\n  max-width: 100%;\n  max-height: 100%;\n}\n\n.display-linebreak {\n  white-space: pre-line;\n}\n\n.extra-check {\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"img": "TemplatePreview-module__NbOm9swpLK7NOS93SwTs",
	"message": "TemplatePreview-module__vJFelPuZL9QlRtTSA9EW",
	"image-result": "TemplatePreview-module__kBQqa84BqJ0KaQc1001Q",
	"display-linebreak": "TemplatePreview-module___0sOoCuTC6fL9KvBdnvv",
	"extra-check": "TemplatePreview-module__bbcpZTKj7Mui49oErh_u"
};
export default ___CSS_LOADER_EXPORT___;
