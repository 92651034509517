// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MSTeamsInfo-module__d_nYyHi58yVvkHjaLM1U {\n  color: var(--primary-text-link);\n}\n\n.MSTeamsInfo-module__mD39EM3cvK8XyQGIAIE2 {\n  display: flex;\n  justify-content: space-between;\n}\n\n.MSTeamsInfo-module__YCxFIM9ZSdgTrSFmMf6C {\n  margin-bottom: 24px;\n}\n", "",{"version":3,"sources":["webpack://./containers/UserSettings/parts/tabs/MSTeamsInfo/MSTeamsInfo.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".verification-code {\n  color: var(--primary-text-link);\n}\n\n.verification-code-text {\n  display: flex;\n  justify-content: space-between;\n}\n\n.heading {\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verification-code": "MSTeamsInfo-module__d_nYyHi58yVvkHjaLM1U",
	"verification-code-text": "MSTeamsInfo-module__mD39EM3cvK8XyQGIAIE2",
	"heading": "MSTeamsInfo-module__YCxFIM9ZSdgTrSFmMf6C"
};
export default ___CSS_LOADER_EXPORT___;
